<template>
  <TextBubble :reply="true">
    <video controls="controls" preload="metadata">
      <source :src="videoSrc" />
    </video>
  </TextBubble>
</template>

<script>
import { ChatBot } from '@appsocially/vue-upil'

const { TextBubble } = ChatBot
export default {
  components: {
    TextBubble
  },
  props: {
    node: {
      type: Object,
      required: false
    }
  },
  computed: {
    event () {
      return this.node.event
    },
    videoUrl () {
      return this.event.value
    },
    videoSrc () {
      return `${this.videoUrl}#t=.01`
    }
  }
}
</script>

<style scoped>
video {
  max-height: 300px;
  max-width: 100%;
}
</style>
